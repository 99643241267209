<template>
	<div>
		<div class="search-box">
			<h2 class="search-title border-bottom">按条件搜索</h2>
			<Form class="search-form" :label-width="85">
				<Form-item label="报告日期">
					<Date-picker format="yyyy-MM-dd" type="daterange" placeholder="选择日期" @on-change="handleChangeReportDate" style="width:300px;margin-right:10px;"></Date-picker>
					<Button type="primary" @click="getReportList">搜索报告</Button>
				</Form-item>
				<Form-item label="选择对象">
					<Row v-if="!branchShow">
						<Col span="5">
						<span class="sub-label">省份：</span>
						<div class="sub-content">
							<Select clearable v-model="searchData.pid" placeholder="请选择..." @on-change="changePro">
								<Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
							</Select>
						</div>
						</Col>
						<Col span="5" offset="1">
						<span class="sub-label">城市：</span>
						<div class="sub-content">
							<Select clearable v-model="searchData.cid" placeholder="请选择..." @on-change="changeCity">
								<Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
							</Select>
						</div>
						</Col>
						<Col span="5" offset="1">
						<span class="sub-label">门店：</span>
						<div class="sub-content">
							<Select clearable v-model="searchData.sid" placeholder="请选择..." @on-change="changeStore">
								<Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
							</Select>
						</div>
						</Col>
						<Col span="5" offset="1">
						<span class="sub-label">会员：</span>
						<div class="sub-content">
							<Select clearable v-model="uid" placeholder="请选择..." filterable @on-change="changeUser">
								<Option :value="v.id" v-for="v in userList" :key="v.id">{{v.true_name}}</Option>
							</Select>
						</div>
						</Col>
					</Row>
					<Row v-else>
						<Col span="5">
						<span class="sub-label">省份：</span>
						<div class="sub-content">
							<Input disabled v-model="proName"></Input>
						</div>
						</Col>
						<Col span="5" offset="1">
						<span class="sub-label">城市：</span>
						<div class="sub-content">
							<Input disabled v-model="cityName"></Input>
						</div>
						</Col>
						<Col span="5" offset="1">
						<span class="sub-label">门店：</span>
						<div class="sub-content">
							<Input disabled v-model="sName"></Input>
						</div>
						</Col>
						<Col span="5" offset="1">
						<span class="sub-label">会员：</span>
						<div class="sub-content">
							<Select clearable v-model="uid" placeholder="请选择..." filterable @on-change="changeUser">
								<Option :value="v.id" v-for="v in userList" :key="v.id">{{v.true_name}}</Option>
							</Select>
						</div>
						</Col>
					</Row>
				</Form-item>
				<Form-item label="报告条件">
					<Row>
						<Col span="10">
						<span class="sub-label">血糖数据：</span>
						<div class="sub-content">
							<Select v-model="queryParams.meal" placeholder="请选择..." @on-change="changeSugarDate" filterable>
								<Option :value="v.rq" v-for="v in mealList" :key="v.id">{{v.name}}</Option>
							</Select>
						</div>
						</Col>
						<Col span="10" offset="1">
						<span class="sub-label">日期：</span>
						<div class="sub-content">
							<Date-picker format="yyyy-MM-dd" type="date" placeholder="选择日期" @on-change="changeSugarDate"
							style="width:200px;margin-right:10px;"></Date-picker>
						</div>
						</Col>
					</Row>
				</Form-item>
				<Form-item label="">
					<Button @click="generateReport" style="margin-right: 4px;">生成报告</Button>
					<!-- <Button @click="windowZoomTip">存为PDF</Button> -->
				</Form-item>
			</Form>
		</div>
		<div class="content-box mb20">
			<h2 class="content-title border-bottom">血糖管理报告</h2>
			<div class="report-body">
				<Table :columns="reportColumns" :data="reportList" :height="220"></Table>
				<div>
					<span class="records">共 {{reportTotal}} 条记录</span>
				</div>
				<div class="page-nations-wrapper">
					<div class="page-nations">
						<Page :total="reportTotal" :current="reportCurrentPage" :page-size="reportData.size" @on-change="changeReportPage"></Page>
					</div>
				</div>
			</div>
		</div>
		<div class="content-box">
			<h2 class="content-title border-bottom">数据结果</h2>
			<sugar-report ref="sugarReport"></sugar-report>
		</div>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import qualityService from '@/services/qualityService';
import comparisonService from '@/services/comparisonService';
import reportSportsService from '@/services/reportSportsService';
import memberService from '@/services/memberService';
import SugarReport from './sugar-report';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
	data() {
		return {
			sugarDate: '',
			searchData: {
				page: 1,
				size: 1000,
				pid: '',
				cid: '',
				sid: '',
				simple_query: 1,
			},
			branchShow: false,
			branch_id: [],
			power: [],
			proName: '',
			cityName: '',
			sName: '',
			storeList: [],
			proList: [],
			cityList: [],
			userList: [],
			mealList: [],
			uid: '',
			queryParams: {
				member_id: '',
				start_time: '',
				end_time: '',
				jblb: [],
				meal: '',
			},
			showModal: false,
			reportColumns: [
				{
					title: '门店',
					key: 'md',
					align: 'center',
				},
				{
					title: '顾客姓名',
					key: 'gkmc',
					align: 'center',
				},
				{
					title: '报告名称',
					key: 'bgmc',
					align: 'center',
				},
				{
					title: '出具日期',
					key: 'cjrq',
					align: 'center',
				},
				{
					title: '检测师',
					key: 'jcs',
					align: 'center',
				},
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						const url = params.row.url;
						return h('div', [
							h('a', {
								class: 'icon_eye',
								attrs: {
									href: url,
									target: '_blank',
								},
							}),
							h('Icon', {
								attrs: {
									type: 'arrow-down-a',
									size: 22,
									color: '#fdad00',
								},
								style: {
									cursor: 'pointer',
								},
								on: {
									click: () => {
										const a = document.createElement('a');
										a.href = url;
										a.style.display = 'none';
										a.setAttribute('download', decodeURI(url.substring(url.lastIndexOf('/') + 1)));
										document.body.appendChild(a);
										a.click();
										document.body.removeChild(a);
									},
								},
							}),
						]);
					},
				},
			],
			reportList: [],
			reportTotal: 0,
			reportCurrentPage: 1,
			reportData: {
				type: 1,
				month_flag: 0,
				pic_type: 36,
				page: 1,
				size: 30,
				pid: '',
				cid: '',
				sid: '',
			},
		};
	},
	computed: {
		...mapGetters({
			userInfo: 'reportUserData',
			sugarData: 'sugarData',
		}),
		disabledDate() {
			const self = this;
			return {
				disabledDate(date) {
					return (
						!self.queryParams.meal ||
						(date &&
							(date.valueOf() > dayjs(self.queryParams.meal).valueOf() ||
								date.valueOf() < dayjs(self.queryParams.meal).subtract(6, 'day').valueOf()))
					);
				},
			};
		},
	},
	components: {
		SugarReport,
	},
	created() {
		let list = JSON.parse(localStorage.getItem('userInfo'));
		qualityService.getQualitySelect().then((data) => {
			this.power = list.user_info.power;
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				if (
					this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
				) {
					this.branchShow = true;
					this.branch_id = list.user_info.branch;
					this.searchData.sid = this.branch_id[2];
					this.searchData.cid = this.branch_id[1];
					this.searchData.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
					this.changeStore();
				}
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	mounted() {
		this.getReportList();
	},
	methods: {
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			this.uid = '';
			if (this.searchData.sid) {
				comparisonService.getUserList(this.searchData).then((data) => {
					this.userList = data.list;
				});
			} else {
				this.userList = [];
			}
		},
		changeUser(val) {
			if (val) {
				reportSportsService
					.getXtsjList({
						member_id: val,
					})
					.then((data) => {
						this.mealList = data;

						this.setSugarEditable(true);
						this.setSugarRate('');
						this.getHeadInfo();
						this.$refs.sugarReport.resetData();
					});
			}
		},

		changeSugarDate(val) {
			this.sugarDate = val;
			reportSportsService
				.getSugarList({
					member_id: this.uid,
					day: val,
				})
				.then((data) => {
					this.setSugarFiveData([
						{
							empty: data.empty,
							halfhour: data.halfhour,
							hour: data.hour,
							twohour: data.twohour,
							threehour: data.threehour,
						},
					]);
					this.setSugarDate(val);
				});
		},
		handleChangeDate(value) {
			this.queryParams.start_time = value;
			if (value) {
				this.queryParams.end_time = dayjs(value).add(6, 'd').format('YYYY-MM-DD');
			}
		},
		...mapActions(['setReportUserData', 'setSugarFiveData']),
		...mapMutations(['setSugarEditable', 'setSugarRate', 'setSugarDate']),
		getHeadInfo() {
			reportSportsService
				.getHeadInfo({
					member_id: this.uid,
				})
				.then((data) => {
					this.setReportUserData(
						Object.assign(data, {
							date: dayjs().format('YYYY-MM-DD'),
							trainStartTime: dayjs(this.queryParams.start_time).format('MM月DD日'),
							trainEndTime: dayjs(this.queryParams.end_time).format('MM月DD日'),
						}),
					);
					this.showModal = true;
				});
		},

		generateReport() {
			if (!this.uid) {
				this.$Message.error('请先选择客户');
				return;
			}
			if (!this.sugarDate) {
				this.$Message.error('请先选择血糖数据');
				return;
			}

			this.$Modal.confirm({
				render: (h) => {
					return [
						h(
							'p',
							{
								style: {
									fontSize: '16px',
								},
							},
							'即将生成报告结果，请确保报告内容编辑完成',
						),
					];
				},
				onOk: () => {
					this._getSugarReport({
						member_id: this.userInfo.member_id,
						data: JSON.stringify(this.sugarData),
					});
					this.setSugarEditable(false);
				},
			});
		},
		windowZoomTip() {
			this.$Modal.confirm({
				render: (h) => {
					return [
						h(
							'p',
							{
								style: {
									fontSize: '16px',
								},
							},
							'为了保证能够较好的导出报告，你需要确保：',
						),
						h(
							'p',
							{
								style: {
									marginTop: '15px',
									fontSize: '16px',
								},
							},
							'1. 显示器分辨率大于等于 1920 * 1080。',
						),
						h(
							'p',
							{
								style: {
									marginTop: '15px',
									fontSize: '16px',
								},
							},
							'2. 使用现代浏览器的最新版本，如 Chrome。',
						),
						h(
							'p',
							{
								style: {
									marginTop: '10px',
									fontSize: '16px',
									lineHeight: 1.5,
								},
							},
							'3. 浏览器缩放必须为 100%，可通过浏览器设置或者快捷键 CTRL + 0 重置缩放。',
						),
					];
				},
				onOk: () => {
					this.saveAsPDF();
				},
			});
		},
		saveAsPDF() {
			const loading = this.$loading({
				lock: true,
				text: '正在存储中，请稍等',
				spinner: 'el-icon-loading',
			});
			// this.setSugarEditable(true)
			setTimeout(() => {
				const printTarget = document.querySelectorAll('.quantify-container');
				if (printTarget.length > 0) {
					const self = this;
					const pdf = new JsPDF({
						compress: true,
						unit: 'pt',
					});
					Promise.all(
						Array.from(printTarget).map((item) =>
							html2canvas(item, {
								scale: 1,
								scrollX: 0,
								scrollY: -window.scrollY,
							}),
						),
					).then((canvasList) => {
						for (let i = 0; i < canvasList.length; i++) {
							const canvas = canvasList[i];
							const contentWidth = canvas.width;
							const contentHeight = canvas.height;
							const imgWidth = 555.28;
							const imgHeight = (imgWidth / contentWidth) * contentHeight;
							const pageData = canvas.toDataURL('image/png', 1);

							if (i !== 0) pdf.addPage();
							pdf.addImage(pageData, 'png', 20, 0, imgWidth, imgHeight);

							if (i === printTarget.length - 1) {
								const time = dayjs().format('YYYYMMDD');
								const fileName = `${self.userInfo.name}${time}血糖管理报告.pdf`;
								const formData = new FormData();
								formData.append('photo', pdf.output('blob'));
								formData.append('check', 1);
								formData.append('name', fileName);
								memberService.uploadReport(formData).then((data) => {
									memberService
										.addReport({
											member_id: self.userInfo.member_id,
											type: 1,
											bgzl: 36,
											check_date: data.check_date,
											photo_id: data.id,
											bgms: '出具报告自动上传',
										})
										.then(() => {
											self.$Message.success('转存成功');
										})
										.catch(() => {
											self.$Message.success('转存失败，请手动上传');
										});
								});
								pdf.save(fileName);
								self.$nextTick(() => {
									loading.close();
								});
							}
						}
					});
				} else {
					this.$Message.warning('无法生成PDF，请检查报告是否存在');
				}
			}, 0);
		},
		handleChangeReportDate(value) {
			this.reportData.start_time = value[0];
			this.reportData.end_time = value[1];
		},
		getReportList() {
			this.reportData.pid = this.searchData.pid;
			this.reportData.cid = this.searchData.cid;
			this.reportData.sid = this.searchData.sid;
			this.reportData.member_id = this.uid;
			memberService.getReportList(this.reportData).then((data) => {
				this.reportList = data.list;
				this.reportTotal = data.row_size;
				this.reportCurrentPage = +data.cur_page;
			});
		},
		// 生成血糖报告
		_getSugarReport(params) {
			reportSportsService.getSugarReport(params).then((res) => {
				this.setSugarRate(res.xtwt);
				this.$Message.success('生成血糖报告成功');
			});
		},
		changeReportPage(page) {
			this.reportData.page = page;
			this.getReportList();
		},
	},
};
</script>

<style>
	.quantify {
		width: 1100px;
	}

	.sub-label {
		vertical-align: middle;
		float: left;
	}

	.sub-content {
		margin-left: 80px;
	}

	.content-title {
		height: 39px;
		line-height: 39px;
		padding-left: 10px;
		background-color: #fbfbfb;
	}

	.quantify-container {
		background-color: #fff;
		padding: 16px;
	}

	.content-box {
		background-color: #fff;
	}

	.report-body {
		margin: 10px;
	}
</style>
