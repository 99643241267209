<template>
	<div class="sugar-container">
		<layout nameCh="血糖分析报告" nameEn="Blood glucose management">
			<div class="sr-header">
				<div class="sr-header-card">
					<!-- <div class="sr-header-time" v-if="editable">
						<img src="@/assets/img/manage/shijian.png" alt="shijian">
						<span>第一口时间</span>
						<Time-picker format="HH:mm" placeholder="选择时间" @on-change="onTimeChange" style="width: 112px"></Time-picker>
					</div> -->
					<span class="sr-header-date">测试日期：{{ today }}</span>
					<div class="sr-header-select">
						<img src="@/assets/img/manage/canyin.png" alt="img">
						<span>测试饮食：</span>
						<span v-if="!editable">{{ dietList[diet - 1].label }}</span>
						<Select v-model="diet" style="width: 200px;" @on-change="changeDiet" v-if="editable">
							<Option v-for="item in dietList" :value="item.value" :key="item.value">{{ item.label }}</Option>
						</Select>
					</div>
				</div>
				<div>
					<el-table :data="sugarTableData" border style="width: 100%">
						<el-table-column prop="empty" label="空腹" align="center">
							<template slot-scope="scope">
								<div class="sr-cell">
									<el-input v-model.trim="scope.row.empty" placeholder="请输入..." @change="onInputChange($event, 0)" v-if="editable">
										<template slot="append">mmol/L</template>
									</el-input>
									<span v-else>{{ scope.row.empty }}mmol/L</span>
									<i :class="{equal: scope.row.empty >= 3.9 && scope.row.empty <= 6.1, up: scope.row.empty > 6.1, down: scope.row.empty < 3.9}"
									v-if="scope.row.empty"></i>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="halfhour" label="餐后0.5h" align="center">
							<template slot-scope="scope">
								<div class="sr-cell">
									<el-input v-model.trim="scope.row.halfhour" placeholder="请输入..." @change="onInputChange($event, 1)" v-if="editable">
										<template slot="append">mmol/L</template>
									</el-input>
									<span v-else>{{ scope.row.halfhour }}mmol/L</span>
									<i :class="{equal: scope.row.halfhour >= 5.1 && scope.row.halfhour <= 7, up: scope.row.halfhour > 7, down: scope.row.halfhour < 5.1}"
									v-if="scope.row.halfhour"></i>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="hour" label="餐后1h" align="center">
							<template slot-scope="scope">
								<div class="sr-cell">
									<el-input v-model.trim="scope.row.hour" placeholder="请输入..." @change="onInputChange($event, 2)" v-if="editable">
										<template slot="append">mmol/L</template>
									</el-input>
									<span v-else>{{ scope.row.hour }}mmol/L</span>
									<i :class="{equal: scope.row.hour >= 6.7 && scope.row.hour <= 9.4, up: scope.row.hour > 9.4, down: scope.row.hour < 6.7}"
									v-if="scope.row.hour"></i>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="twohour" label="餐后2h" align="center">
							<template slot-scope="scope">
								<div class="sr-cell">
									<el-input v-model.trim="scope.row.twohour" placeholder="请输入..." @change="onInputChange($event, 3)" v-if="editable">
										<template slot="append">mmol/L</template>
									</el-input>
									<span v-else>{{ scope.row.twohour }}mmol/L</span>
									<i :class="{equal: scope.row.twohour >= 3.9 && scope.row.twohour <= 7.8, up: scope.row.twohour > 7.8, down: scope.row.twohour < 3.9}"
									v-if="scope.row.twohour"></i>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="threehour" label="餐后3h" align="center">
							<template slot-scope="scope">
								<div class="sr-cell">
									<el-input v-model.trim="scope.row.threehour" placeholder="请输入..." @change="onInputChange($event, 4)" v-if="editable">
										<template slot="append">mmol/L</template>
									</el-input>
									<span v-else>{{ scope.row.threehour }}mmol/L</span>
									<i :class="{equal: scope.row.threehour >= 3.9 && scope.row.threehour <= 6.1, up: scope.row.threehour > 6.1, down: scope.row.threehour < 3.9}"
									v-if="scope.row.threehour"></i>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<!-- 要素服用情况 -->
			<div class="sr-panel">
				<div class="sr-panel-header">
					<div class="sph-title">
						<img src="@/assets/img/manage/yaoxiang.png" alt="yaoxiang">
						<span>要素服用情况</span>
					</div>
					<Button type="info" @click="addMedicineHandle(1)" v-if="editable">添加用药</Button>
				</div>
				<div class="sr-panel-content">
					<el-table :data="elementsTableData" border empty-text="测试时间段内未用药" style="width: 100%">
						<el-table-column prop="time" label="用药时间" align="center">
							<template slot-scope="scope">
								<el-select v-model="scope.row.time" placeholder="请选择" @change="onSelectChange" v-if="editable">
									<el-option v-for="item in timeList" :key="item.value" :label="item.label" :value="item.value">
									</el-option>
								</el-select>
								<span v-else>{{ scope.row.time ? timeList[scope.row.time - 1].label : '' }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="medicines" label="药名" align="center">
							<template slot-scope="scope">
								<el-select v-model="scope.row.medicines" placeholder="请选择" @change="onSelectChange" v-if="editable">
									<el-option v-for="item in medicinesList" :key="item.value" :label="item.label" :value="item.value">
									</el-option>
								</el-select>
								<span v-else>{{ scope.row.medicines ? medicinesList[scope.row.medicines - 1].label : '' }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="dose" label="剂量" align="center">
							<template slot-scope="scope">
								<!-- <el-select v-model="scope.row.dose" placeholder="请选择" @change="onSelectChange" v-if="editable">
									<el-option v-for="item in doseList" :key="item.value" :label="item.label" :value="item.value">
									</el-option>
								</el-select> -->
								<!-- <Input-number :min="0" v-model="scope.row.dose" placeholder="请输入..." size="middle" @on-change="onSelectChange" v-if="editable"></Input-number> -->
								<el-input-number v-model="scope.row.dose" controls-position="right" size="small" @change="onSelectChange" :min="0" v-if="editable"></el-input-number>
								<!-- <el-input v-model="scope.row.dose" placeholder="请输入..." @change="onSelectChange" v-if="editable"></el-input> -->
								<span v-else>{{ scope.row.dose }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="unit" label="单位" align="center">
							<template slot-scope="scope">
								<el-select v-model="scope.row.unit" placeholder="请选择" @change="onSelectChange" v-if="editable">
									<el-option v-for="item in unitList" :key="item.value" :label="item.label" :value="item.value">
									</el-option>
								</el-select>
								<span v-else>{{ unitList[scope.row.unit - 1].label }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="way" label="用药方式" align="center">
							<template slot-scope="scope">
								<el-select v-model="scope.row.way" placeholder="请选择" @change="onSelectChange" v-if="editable">
									<el-option v-for="item in wayList" :key="item.value" :label="item.label" :value="item.value">
									</el-option>
								</el-select>
								<span v-else>{{ scope.row.way ? wayList[scope.row.way - 1].label : '' }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="remark" label="备注" align="center">
							<template slot-scope="scope">
								<el-input v-model="scope.row.remark" placeholder="请输入..." @change="onSelectChange" v-if="editable"></el-input>
								<span v-else>{{ scope.row.remark }}</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<!-- 运动情况 -->
			<div class="sr-panel">
				<div class="sr-panel-header">
					<div class="sph-title">
						<img src="@/assets/img/manage/paobu.png" alt="paobu">
						<span>测量前运动情况</span>
					</div>
					<Button type="info" @click="addMedicineHandle(2)" v-if="editable">添加运动</Button>
				</div>
				<div class="sr-panel-content">
					<el-table :data="sportsTableData" border empty-text="测试时间段内无运动" style="width: 100%">
						<el-table-column prop="time" label="测试节点" align="center">
							<template slot-scope="scope">
								<el-select v-model="scope.row.time" placeholder="请选择" @change="onSportsTimeChange($event, scope.$index)" v-if="editable">
									<el-option v-for="item in timeList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
									</el-option>
								</el-select>
								<span v-else>{{ scope.row.time ? timeList[scope.row.time - 1].label : '' }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="exercise" label="运动强度" align="center">
							<template slot-scope="scope">
								<el-select v-model="scope.row.exercise" placeholder="请选择" @change="onExerciseChange" v-if="editable">
									<el-option v-for="item in exerciseList" :key="item.value" :label="item.label" :value="item.value">
									</el-option>
								</el-select>
								<span v-else>{{ scope.row.exercise ? exerciseList[scope.row.exercise - 1].label : '' }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="duration" label="时长" align="center">
							<template slot-scope="scope">
								<el-input v-model.trim="scope.row.duration" placeholder="请输入..." @input="onDurationChange($event, scope.$index)"
								v-if="editable">
									<template slot="append">min</template>
								</el-input>
								<span v-else>{{ scope.row.duration }}min</span>
							</template>
						</el-table-column>
						<el-table-column prop="content" label="运动内容" align="center">
							<template slot-scope="scope">
								<el-input v-model="scope.row.content" placeholder="请输入..." @change="onSelectChange" v-if="editable"></el-input>
								<span v-else>{{ scope.row.content }}</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<!-- OGTT胰岛功能曲线 -->
			<div class="sr-panel">
				<div class="sr-panel-header">
					<div class="sph-title">
						<img src="@/assets/img/manage/fund.png" alt="fund" style="width: 48px;">
						<div class="sph-title-wrap">
							<h3 class="title-ch">OGTT胰岛功能分析</h3>
							<p class="title-en">OGTT islet function curve</p>
						</div>
					</div>
				</div>
				<div class="sr-panel-content">
					<line-chart :sugar-data="sugarData"></line-chart>
				</div>
			</div>
			<!-- 血糖测试分析与指导 -->
			<div class="sr-panel">
				<div class="sr-panel-header">
					<div class="sph-title">
						<img src="@/assets/img/manage/baogaogongdan.png" alt="baogaogongdan" style="width: 48px;">
						<div class="sph-title-wrap">
							<h3 class="title-ch">血糖测试分析与指导</h3>
							<p class="title-en">Blood glucose management report</p>
						</div>
					</div>
					<div class="sign-wrap">
						<span>检测师: </span><Input type="text" v-model="signGuider" @on-change="onSelectChange" style="width: 90px;"></Input>
					</div>
				</div>
				<div class="sr-panel-content">
					<div class="textarea-content">
						<div class="textarea-wrap" v-html="rate"></div>
						<h2 class="rate-title">专家评价：</h2>
						<div class="textarea-card">
							<Input v-model="textareaValue" type="textarea" :rows="4" placeholder="请输入..." @on-change="onSelectChange"></Input>
						</div>
					</div>
				</div>
			</div>
			<div class="save-wrap" v-if="savePDFButton">
				<Button @click="windowZoomTip">存为PDF</Button>
			</div>
		</layout>
	</div>
</template>

<script>
import Layout from './layout';
import lineChart from './lineChart';
import dayjs from 'dayjs';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import memberService from '@/services/memberService';
import reportSportsService from '@/services/reportSportsService';
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
	components: {
		Layout,
		lineChart,
	},
	data() {
		return {
			savePDFButton: true,
			firstTime: '',
			diet: 1,
			dietList: [
				{
					label: '二两馒头',
					value: 1,
				},
				{
					label: '75g葡萄糖',
					value: 2,
				},
				{
					label: '实际早餐',
					value: 3,
				},
				{
					label: '测试早餐',
					value: 4,
				},
			],
			elementsTableData: [], // 要素服用情况数据
			timeList: [
				{
					label: '空腹',
					value: 1,
					disabled: false,
				},
				{
					label: '餐后0.5小时',
					value: 2,
					disabled: false,
				},
				{
					label: '餐后1小时',
					value: 3,
					disabled: false,
				},
				{
					label: '餐后2小时',
					value: 4,
					disabled: false,
				},
				{
					label: '餐后3小时',
					value: 5,
					disabled: false,
				},
			],
			medicinesList: [
				{
					label: '降糖药',
					value: 1,
				},
				{
					label: '其他药',
					value: 2,
				},
				{
					label: '保健品',
					value: 3,
				},
				{
					label: '近体物质',
					value: 4,
				},
			],
			doseList: [
				{
					label: '1',
					value: 1,
				},
				{
					label: '2',
					value: 2,
				},
				{
					label: '3',
					value: 3,
				},
			],
			unitList: [
				{
					label: 'U',
					value: 1,
				},
				{
					label: 'mg',
					value: 2,
				},
				{
					label: '片',
					value: 3,
				},
				{
					label: '粒',
					value: 4,
				},
				{
					label: '袋',
					value: 5,
				},
			],
			wayList: [
				{
					label: '口服',
					value: 1,
				},
				{
					label: '注射',
					value: 2,
				},
				{
					label: '其他',
					value: 3,
				},
			],
			sportsTableData: [], // 运动情况
			exerciseList: [
				{
					label: '低等强度:散步、做家务、外出步行等',
					value: 1,
				},
				{
					label:
						'中等强度:慢跑、快走、骑自行车、乒乓球、羽毛球、广场舞、门球、按摩操、太极拳、器械训练等',
					value: 2,
				},
				{
					label: '不运动，静坐为主',
					value: 3,
				},
				{
					label: '高等强度:跳绳、快跑、游泳、网球、健身操、搬运重物',
					value: 4,
				},
			],
			signGuider: '',
			sugarData: [], // 编辑后五点血糖数据
			textareaValue: '',
			sportExercise: '', // 运动强度
		};
	},
	computed: {
		...mapGetters({
			editable: 'sugarEditable',
			rate: 'sugarRate',
			sugarTableData: 'sugarFiveData',
			today: 'sugarDate',
			userInfo: 'userInfo',
			userData: 'reportUserData',
		}),
	},
	mounted() {
		this.signGuider = this.userInfo.name;
	},
	methods: {
		...mapActions(['setSugarData', 'setSugarFiveData']),
		...mapMutations(['setSugarEditable']),
		addMedicineHandle(type) {
			if (type == 1) {
				// 要素情况
				if (this.elementsTableData.length >= 1) {
					this.$Message.warning('最多只能添加1条要素服务情况');
					return;
				}
				this.elementsTableData.push({
					time: '',
					medicines: '',
					dose: 0,
					unit: '',
					way: '',
					remark: '',
				});
			} else {
				if (this.sportsTableData.length >= 5) {
					this.$Message.warning('最多只能添加5条运动情况');
					return;
				}
				this.sportsTableData.push({
					time: '',
					exercise: '',
					duration: '',
					content: '',
				});
			}
		},
		changeDiet() {
			this.setSugarFiveData([
				{
					empty: '',
					halfhour: '',
					hour: '',
					twohour: '',
					threehour: '',
				},
			]);
		},
		onControlChange(val) {
			this.sugarData = [];
			if (val) {
				for (let key in this.sugarTableData[0]) {
					this.sugarData.push(this.sugarTableData[0][key]);
				}
			}
			this.setSugarData(Object.assign({}, this.joinData()));
		},
		onSelectChange() {
			// 数据顺序：第一口时间，测试饮食，测试日期，5个血糖值，要素服用情况，运动情况，专家情况
			this.setSugarData(Object.assign({}, this.joinData()));
		},
		onTimeChange(val) {
			this.firstTime = val;
		},
		onInputChange(val, index) {
			if (Number(val) < 2 || Number(val) > 20) {
				if (index == 0) {
					this.sugarTableData[0].empty = '';
				} else if (index == 1) {
					this.sugarTableData[0].halfhour = '';
				} else if (index == 2) {
					this.sugarTableData[0].hour = '';
				} else if (index == 3) {
					this.sugarTableData[0].twohour = '';
				} else if (index == 4) {
					this.sugarTableData[0].threehour = '';
				}
			}

			this.sugarData = [
				this.sugarTableData[0].empty,
				this.sugarTableData[0].halfhour,
				this.sugarTableData[0].hour,
				this.sugarTableData[0].twohour,
				this.sugarTableData[0].threehour,
			];
			this.setSugarData(Object.assign({}, this.joinData()));
		},
		// 测试节点
		onSportsTimeChange(val) {
			this.timeList.forEach((item) => {
				if (item.value === val) {
					item.disabled = true;
				}
			});
		},
		// 运动强度
		onExerciseChange(val) {
			this.sportExercise = val;
		},
		// 运动时长
		onDurationChange(val, index) {
			console.log(val, index);
			if (this.sportExercise == 2) {
				if (Number(val) > 60) {
					this.sportsTableData[index].duration = 60;
				}
			} else if (this.sportExercise == 1 || this.sportExercise == 3) {
				if (Number(val) > 30) {
					this.sportsTableData[index].duration = 30;
				}
			}
		},
		joinData() {
			return {
				testDiet: this.dietList[this.diet - 1].label,
				testDate: this.today,
				fivesugar: this.sugarData,
				elements: this.elementsTableData,
				sports: this.sportsTableData,
				rate: this.textareaValue,
				detecter: this.signGuider,
			};
		},
		resetData() {
			this.setSugarFiveData([
				{
					empty: '',
					halfhour: '',
					hour: '',
					twohour: '',
					threehour: '',
				},
			]);
			this.timeList.forEach((item) => {
				item.disabled = false;
			});
			this.elementsTableData = [];
			this.sugarData = [];
			this.sportsTableData = [];
		},
		windowZoomTip() {
			this.$Modal.confirm({
				render: (h) => {
					return [
						h(
							'p',
							{
								style: {
									fontSize: '16px',
								},
							},
							'为了保证能够较好的导出报告，你需要确保：',
						),
						h(
							'p',
							{
								style: {
									marginTop: '15px',
									fontSize: '16px',
								},
							},
							'1. 显示器分辨率大于等于 1920 * 1080。',
						),
						h(
							'p',
							{
								style: {
									marginTop: '15px',
									fontSize: '16px',
								},
							},
							'2. 使用现代浏览器的最新版本，如 Chrome。',
						),
						h(
							'p',
							{
								style: {
									marginTop: '10px',
									fontSize: '16px',
									lineHeight: 1.5,
								},
							},
							'3. 浏览器缩放必须为 100%，可通过浏览器设置或者快捷键 CTRL + 0 重置缩放。',
						),
					];
				},
				onOk: () => {
					this.savePDFButton = false;
					this.saveAsPDF();
				},
			});
		},
		saveAsPDF() {
			const loading = this.$loading({
				lock: true,
				text: '正在存储中，请稍等',
				spinner: 'el-icon-loading',
			});
			this.setSugarEditable(false);
			setTimeout(() => {
				const printTarget = document.querySelectorAll('.quantify-container');
				if (printTarget.length > 0) {
					const self = this;
					const pdf = new JsPDF({
						compress: true,
						unit: 'pt',
					});
					Promise.all(
						Array.from(printTarget).map((item) =>
							html2canvas(item, {
								scale: 1,
								scrollX: 0,
								scrollY: -window.scrollY,
							}),
						),
					).then((canvasList) => {
						for (let i = 0; i < canvasList.length; i++) {
							const canvas = canvasList[i];
							const contentWidth = canvas.width;
							const contentHeight = canvas.height;
							const imgWidth = 555.28;
							const imgHeight = (imgWidth / contentWidth) * contentHeight;
							const pageData = canvas.toDataURL('image/png', 1);

							if (i !== 0) pdf.addPage();
							pdf.addImage(pageData, 'png', 20, 0, imgWidth, imgHeight);

							if (i === printTarget.length - 1) {
								const time = dayjs().format('YYYYMMDD');
								const fileName = `${self.userData.name}${time}血糖管理报告.pdf`;
								const formData = new FormData();
								formData.append('photo', pdf.output('blob'));
								formData.append('check', 1);
								formData.append('name', fileName);
								reportSportsService
									.getSugarReport({
										member_id: self.userData.member_id,
										data: this.sugarData,
										finish: 1,
									})
									.then(() => {
										memberService.uploadReport(formData).then((data) => {
											memberService
												.addReport({
													member_id: self.userData.member_id,
													type: 1,
													bgzl: 36,
													check_date: data.check_date,
													photo_id: data.id,
													bgms: '出具报告自动上传',
												})
												.then(() => {
													self.$Message.success('转存成功');
												})
												.catch(() => {
													self.$Message.success('转存失败，请手动上传');
												});
										});
									});
								pdf.save(fileName);
								self.$nextTick(() => {
									loading.close();
								});
							}
						}
					});
				} else {
					this.$Message.warning('无法生成PDF，请检查报告是否存在');
				}
			}, 0);
		},
	},
	watch: {
		sugarTableData() {
			this.sugarData = [
				this.sugarTableData[0].empty,
				this.sugarTableData[0].halfhour,
				this.sugarTableData[0].hour,
				this.sugarTableData[0].twohour,
				this.sugarTableData[0].threehour,
			];
			this.setSugarData(Object.assign({}, this.joinData()));
		},
	},
};
</script>

<style lang="less" scoped>
	.sugar-container {
		color: #056add;
		font-size: 18px;

		.sr-header {
			.sr-header-card {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 10px 0;

				.sr-header-date,
				.sr-header-select {
					font-weight: 700;
				}

				.sr-header-time {
					display: flex;
					align-items: center;

					>img {
						width: 36px;
					}

					>span {
						margin-right: 8px;
						font-weight: 700;
						font-size: 18px;
					}
				}

				.sr-header-select {
					>img {
						width: 36px;
					}
				}
			}

			.ivu-select,
			.ivu-select-placeholder,
			.ivu-select-selected-value,
			.ivu-select-item {
				color: #056add;
			}
		}

		.sr-cell {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #056add;

			.up,
			.down {
				width: 0;
				height: 0;
				border: 10px solid;
				margin-left: 8px;
			}

			.up {
				border-color: transparent transparent #ff4444;
				margin-top: -14px;
			}

			.down {
				border-color: #ff9743 transparent transparent;
				margin-top: 6px;
			}

			.equal {
				width: 20px;
				height: 3px;
				background-color: rgb(43, 172, 0);
				margin-left: 8px;
			}
		}

		.sr-panel {
			margin-top: 20px;

			.sr-panel-header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 15px;

				.sph-title {
					font-weight: 700;
					display: flex;
					align-items: center;

					>img {
						width: 24px;
						margin-right: 8px;
					}
				}

				.sph-title-wrap {
					display: flex;
					flex-direction: column;
					justify-content: center;

					.title-ch {
						font-size: 18px;
						font-weight: 700;
					}

					.title-en {
						font-size: 14px;
						font-weight: 400;
					}
				}
			}

			.chart {
				width: 100%;
				height: 340px;
			}

			.sign-wrap {
				float: right;
				color: #005AB3;
				font-size: 16px;
				display: flex;
				align-items: flex-end;

				>span {
					width: 62px;
				}
			}

			.textarea-content {
				border: 1px solid #297ddf;
				padding: 10px;
			}

			.textarea-wrap {
				width: 100%;
				margin-bottom: 10px;
				color: #005AB3;
			}

			.textarea-card {
				width: 100%;
				min-height: 120px;
				color: #005AB3;
			}

			.rate-title {
				font-weight: 700;
				font-size: 18px;
				padding-bottom: 10px;
				padding-top: 10px;
			}
		}
		.save-wrap {
			margin-top: 20px;
			text-align: right;
		}
	}
</style>
